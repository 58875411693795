
import {
  onUnmounted,
  onMounted,
  defineComponent,
  inject,
  reactive,
  ref,
  watch
} from "vue";
import { PageParams } from "@/apis/interface/PageParams";
import { RespBodyPager } from "@/apis/interface/Resp";
import {
  IUserFavorite,
  UserFavoriteFilter,
  UserFavoriteModel
} from "@/apis/interface/IUserFavorite";
import bus from "@/utils/bus";

export default defineComponent({
  name: "List",
  components: {},
  setup() {
    const apiUserFavorite = inject("apiUserFavorite") as IUserFavorite;

    const pageParams: PageParams = reactive({
      aaIndex: 1,
      aaSize: 50
    });

    const filter: UserFavoriteFilter = reactive({
      favTable: "",
      userId: null
    });

    let clearFilter = () => {
      filter.favTable = "";
      filter.userId = null;
      pageParams.aaIndex = 1;
      loadData();
    };

    let dataList = ref<UserFavoriteModel[]>([]);
    let loading = ref(false);

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    let loadData = async () => {
      loading.value = true;
      dataList.value = [];
      const resp = await apiUserFavorite.getList(pageParams, filter);
      const pager = resp as RespBodyPager<UserFavoriteModel>;
      pageParams.aaSize = pager.object.pageSize;
      pageParams.aaIndex = pager.object.pageCurrent;
      pageParams.total = pager.object.totalCount;
      dataList.value = pager.object.listData;
      // console.log("==>", dataList);
      loading.value = false;
    };

    let statusFormat = (status: number) => {
      // console.log(status);
      return "正常";
    };

    onMounted(() => {
      loadData();
      bus.on("on-table-row-edit-ok", () => {
        loadData();
      });
    });

    let deleteRow = async (id: number) => {
      alert("不支持" + id);
    };

    watch(
      () => pageParams.aaIndex,
      () => {
        loadData();
      }
    );

    let tableHeight = ref(0);

    return {
      loading,
      tableHeight,
      pageParams,
      filter,
      dataList: dataList,
      statusFormat,
      loadData,
      deleteRow,
      clearFilter
    };
  }
});
